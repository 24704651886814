import React, { Component } from "react"
import styled, { css } from 'styled-components'
import { Link, navigate } from 'gatsby'
import { find } from 'lodash'
import { connect } from 'react-redux'

import { Hemisphere, Layout } from "../components";
import { News } from "../modules";

import { container, heading, bgImage, button } from "../styles/global"
import { red, blue, pink, yellow } from "../styles/colors";
import nav from '../data/nav'
import { isClient, media, width } from "../styles/utils";
import { resolveComponent } from "../utils";
import { getSettings, fetchSettings } from "../store/settings";
import Cart from "../components/Cart/Cart";

class Home extends Component {

	state = {
		leftRoute: nav.defaultRoutes.left,
		rightRoute: nav.defaultRoutes.right
	}

	componentWillMount = () => {	
		this.resolveRoute()
		this.props.fetchSettings()
	}

	resolveRoute = () => {
		const pathContext = this.props.pathContext;

		if (pathContext.leftRoute) {
			const component = resolveComponent(pathContext.leftRoute);
			this.updateRoute('left', component)
		}
		
		if (pathContext.rightRoute) {
			const component = resolveComponent(pathContext.rightRoute);
			this.updateRoute('right', component)
		}
	}

	componentDidMount = () => {
		const pathContext = this.props.pathContext;
		setTimeout(() => {
			if (isClient() && !pathContext.rightRoute && !pathContext.leftRoute) {
				window.history.pushState(null, null, '/');
			}
		}, 100);
	}
	
	updateRoute = (hemisphere, component) => {
		if (width.tablet()) {
			this.setState({
				[`leftRoute`]: component
			})
		} else {
			this.setState({
				[`${hemisphere}Route`]: component
			})
		}
	}

  	render() {
		const { leftRoute, rightRoute } = this.state;
		const { settings } = this.props;

		return (
			<Layout>
				<Wrapper>

					{/* Left */}

					<Hemisphere
						type={'left'}
						background={leftRoute.type.background || pink}
						navItems={width.tablet() ? nav.left.concat(nav.right) : nav.left}
						currentRoute={leftRoute}
						updateRoute={this.updateRoute}
						data={this.props.data}
						pathContext={this.props.pathContext}
					/>

					{/* Right */}

					<Hemisphere
						type={'right'}
						background={rightRoute.type.background || yellow}
						navItems={nav.right}
						currentRoute={rightRoute}
						updateRoute={this.updateRoute}
						data={this.props.data}
						pathContext={this.props.pathContext}
					/>

					{/* Cart */}

					<Cart
						cartActive={settings.cartActive}
					/>

				</Wrapper>
			</Layout>
		)	
  	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;	
	width: 100%;
	min-height: 100vh;
	max-height: 100vh;
	overflow: hidden;

	${media.tablet`
		flex-direction: column;
		max-height: none;
	`}
`

const mapDispatchToProps = dispatch => ({
    fetchSettings() {
        dispatch(fetchSettings())
    },
    updateSettings(setting) {
        dispatch(updateSettings(setting))
    },
})

const mapStateToProps = (state, props) => ({
    settings: state.settings.settings
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);

// Set here the ID of the home page.

export const pageQuery = graphql`
	query SiteData {
		...artists
		...news
		...about
		...artistMusic
		...radio
		...privacy
		
		wordpressArtistsPage {
			acf_json 
		}
	}
`
import React, { Component } from "react"
import { About, News, Artists, Releases, Music, Radio, Privacy } from "../modules"

const data = {
    defaultRoutes: {
        left: <About/>,
        right: <Artists/>
    },
    left: [
        {
            name: 'About',
            component: <About/>
        },
        // {
        //     name: 'News',
        //     component: <News/>
        // },
        // {
        //     name: 'Music',
        //     component: <Music/>
        // },
        {
            name: 'Radio',
            component: <Radio/>
        },
        {
            name: 'Privacy',
            component: <Privacy/>,
            hide: true
        }
    ],
    right: [    
        {
            name: 'Artists',
            component: <Artists/>
        },
        {
            name: 'Shop',
            link: 'https://sound-merch.com.au/collections/music-in-exile',
        },
        // {
        //     name: 'Subscribe',
        //     link: 'https://www.google.com'
        // }
    ]
}

export default data